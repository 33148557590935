.wrapper {
  color: #40475d;
  margin-bottom: 50px;
  .container {
    padding: 20px;
    background-color: #fff;
    border-radius: 4px;
  }

  .dropdownFilter {
    margin-bottom: 20px;
    position: relative;
    max-width: 200px;
    width: 100%;
    padding: 0;
    margin-right: 20px;
    z-index: 2;

    input {
      cursor: pointer;
      background-repeat: no-repeat;
      background-position: 95% center;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='2.8125' y='2.8125' width='12.375' height='12.375' rx='0.5625' stroke='%234F75FF' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.3898 1.6875V3.9375' stroke='%234F75FF' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M5.63984 1.6875V3.9375' stroke='%234F75FF' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M2.8125 6.20234H15.1875' stroke='%234F75FF' stroke-width='1.125' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  .tableWrap {
    padding-top: 20px;
    border-radius: 10px;
  }

  .title {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.188235px;
    color: #40475d;
  }
}

.form {
  display: flex;
  justify-content: space-between;
  width: 200px;
  button {
    padding: 2px;
    border: solid 1px #ced4da;
    border-radius: 5px;
    background-color: #fff;
    height: 21px;
    width: 21px;
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
    left: -8px;
    svg {
      height: 18px;
      width: 18px;
    }
  }
}

.formHeader {
  display: flex;
  justify-content: space-between;
  width: 325px;
  margin-bottom: 17px;
  button {
    padding: 7px;
    border: solid 1px #ced4da;
    border-radius: 5px;
    background-color: #fff;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-items: center;
    position: relative;
    left: -4px;
    svg {
      height: 18px;
      width: 18px;
    }
  }
}

.inputHeader {
  input {
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    height: 34px;
    padding: 5px 7px;
    position: relative;
    left: -8px;
    border: solid 1px #ced4da;
    width: 300px;
    border-radius: 0.25rem;
    &:focus {
      box-shadow: none;
      border: solid 1px #ced4da;
    }
  }
}

.width {
  max-width: 140px;
}

.pir {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 1400px) {
    max-width: 190px;
  }
}

.selectInput {
  & > div > div:first-of-type {
    box-shadow: none!important;
    outline: none!important;
    border-color: #ced4da !important;
  }
  & > div > div > div {
    padding-left: 7px;
    max-height: 21px;
    font-size: 0.88rem;
    width: 212px;
  }
  & > div > div:first-of-type span button {
    height: 21px!important;
    position: relative;
    bottom: 1px;
  }
  & > div > div:nth-child(2) {
    margin-top: 2px;
  }
}

.selectPIR {
  & > div > div > div {
    width: 185px;
    padding-right: 10px;
  }
  & > div > div {
    width: 169px;
  }
  & > div > div:first-of-type span button {
    left: 14px;
  }
  svg {
    right: -113px!important;
    bottom: 9px!important;
  }
}

.select {
  width: 200px;
  position: relative;
  left: -8px;
}

.selectPIR {
  width: 195px;
  position: relative;
  left: -8px;
  margin-left: 4px;
}

.iconPosition {
  svg {
    position: relative;
    bottom: 11px;
    right: 5px;
    cursor: pointer;
  }
}

.inputShow {
  input {
    font-size: 0.88rem;
    height: 21px;
    padding: 2px 7px;
    position: relative;
    left: -8px;
    border: solid 1px #ced4da;
    width: 175px;
    border-radius: 0.25rem;
    &:focus {
      box-shadow: none;
      border: solid 1px #ced4da;
    }
  }
}

.tWrapper {
  padding: 24px 20px 20px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(64, 71, 93, 0.1);
  border-radius: 0 4px 4px 4px;
}

.tdWidth {
  width: 25%;
}

.firstCellWidth {
  width: 40px;
}

.tabs {
  display: flex;
  max-width: 31%;
}

.tab {
  padding: 15px 0 14px 20px;
  text-align: left;
  width: 50%;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: rgba(64, 71, 93, 0.5);
  cursor: pointer;
  border: none;
  border-bottom: 2px solid #e9ecef;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.tab:not(:last-child) {
  border-right: 2px solid #e9ecef;
}

.activeTab {
  padding: 15px 0 14px 20px;
  text-align: left;
  width: 50%;
  background: #ffffff;
  font-size: 14px;
  font-weight: 500;
  color: #40475d;
  border: none;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  position: relative;
}

.activeTab:not(:last-child) {
  border-right: 2px solid #e9ecef;
}

.hideContent {
  display: none;
}

.tableWrapper {
  border: 1px solid #e6e7ed;
  border-radius: 4px;
  overflow-x: hidden;

  .tableContainer {
    color: #40475d;

    thead th {
      font-weight: 500;
      border-top: none;
      border-bottom: none;
    }
  }

  .footerTableContainer {
    color: #40475d;

    tbody td {
      font-weight: 400;
      height: 40px;
      border-bottom: none;
    }
  }

  .buttonSecton {
    display: flex;
    justify-content: center;
  }

  .firstCell {
    padding-left: 30px;
  }

  .lastCell {
    padding-right: 30px;
  }
}

.marginBottomTable {
  margin-bottom: 10px;
}

.minWidth1000 {
  min-width: 1000px;
}

.minWidth1300 {
  min-width: 1300px;
}

.textCapitalize {
  text-transform: capitalize;
}

.minWidth1400 {
  min-width: 1400px;
}

.buttonWrap {
  display: flex;
  gap: 13px;
  justify-content: flex-end;
}

.button {
  background: #4f75ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px 32px 10px 34px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    background: #3f5fd4;
  }
  &:disabled {
    background: #dce3ff;
    color: #bec1cc;

    svg {
      path {
        fill: #bec1cc;
        stroke: #bec1cc;
      }
    }
  }

  svg {
    path {
      fill: #fff;
      stroke: #fff;
    }
  }
}

.deleteBtn {
  background: #ff4f5b;

  &:hover {
    background: #d43f49;
  }
  &:disabled {
    background: #ffdcde;
    color: #bec1cc;
  }
}

.addButton {
  display: flex;
  white-space: nowrap;
  gap: 10px;
  align-items: center;
}

.topButton {
  top: 40px;
  right: 30px;
  position: absolute;
  display: flex;
  gap: 10px;
  align-items: center;
}

.lineButton {
  white-space: nowrap;
  background: transparent;
  color: #4f75ff;
  border: 1px solid #4f75ff;
  border-radius: 4px;
  padding: 10px 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    cursor: pointer;
    background: #dce3ff;
    color: #4f75ff;
    text-decoration: none;
  }
}

.textEllipsis {
  text-overflow: ellipsis;
  overflow: hidden;
}

.inputWrap {
  margin-right: 20px;
  max-width: 280px;
  width: 100%;
}

.buttonsWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  button {
    padding: 5px 12px;
    border-radius: 4px;
    font-size: 14px;
    text-transform: uppercase;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 18px 18px;
    border: none;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-out;
    margin-left: 10px;

    &:hover {
      opacity: 0.8;
    }
  }

  .edit {
    background-color: #4f75ff;
    color: #ffffff;
  }

  .delete {
    border: 1px solid #ff4f5b;
    color: #ff4f5b;

    &:hover {
      background-color: #ff4f5b;
      color: #fff;

      img {
        filter: brightness(9);
      }
    }
  }

  .success {
    background-color: #3ac47d;
    color: #ffffff;
  }

  .disabled {
    background-color: #aaa;
    border-color: grey;
    color: #ffffff;
    transition: none;

    &:hover {
      transform: none;
    }
  }
}

.searchKeyWrap {
  position: relative;
  display: flex;
  align-items: center;

  input {
    height: 40px;
    font-size: 15px;
  }

  .buttonSearch {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 38px;
    height: 38px;
    border: 0;
    background-color: transparent;
  }
}

.tableRowList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.yellowRow {
  background-color: #ffedda !important;
}

.greenRow {
  background-color: #daf4e1 !important;
}

.redRow {
  background-color: #ffdcde !important;
}

.purpleRow {
  background-color: #e6dbff;
}

.bpHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.checkboxFilter {
  display: flex;
  align-items: center;
  margin-left: 27px;
}

.checkboxWrapper {
  box-shadow: none;
  width: 16px;
  height: 16px;

  &:focus {
    box-shadow: none;
  }

  &:checked {
    box-shadow: none;
  }
}

.checkboxText {
  white-space: nowrap;
  margin: 5px 0 0 3px;
  color: #40475d;
  font-size: 14px;
}

.dateWrap {
  margin: 0 16px 0 24px;
}

.dateRow {
  white-space: nowrap;
}

.labelMargin {
  margin-bottom: 14px;
}

.rowWrapper {
  width: 50%;
  background: #f0f4f6;
}

.itemsWrapper {
  display: flex;
  align-items: center;
}

.dropdownWrapper {
  margin-bottom: -16px;
  span {
    display: none;
  }
}

.normalRow {
  ul {
    height: 106px;
    overflow: auto;
  }
}

.oneRow {
  ul {
    height: 45px;
    overflow: auto;
  }
}

.secondRow {
  ul {
    height: 97px;
    overflow: auto;
  }
}

.rowHover {
  &:hover {
    background: #dce3ff;
  }
}

.removeTopBorder {
  td {
    border-top: none;
  }
}

// Year picker
.arrow {
  svg {
    &:hover {
      cursor: pointer;
      path {
        stroke: #7f8495;
      }
    }
  }
}

.currentYear {
  font-size: 20px;
  font-weight: 700;
  margin: 0 17px;
}

// to remove additional table border on pages without pagination
.removeBorder {
  td {
    border-bottom: none;
  }
}

// footer table for Resource and Overtime Request pages
.footerTableWrap {
  table {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 4px;
    overflow: hidden;

    tbody {
      td {
        border-right: none;
      }

      td:not(:first-child) {
        border-top: none;
      }
    }

    thead {
      tr {
        th {
          border-right: none;
        }
      }
    }

    th,
    td {
      border: 1px solid #e6e7ed;

      &:first-child {
        border-left: 1px solid #e6e7ed;
      }

      &:last-child {
        border-right: 1px solid #e6e7ed;
      }
    }

    thead tr:first-child th:first-child + th {
      border-top-left-radius: 4px;
    }

    thead tr:last-child th:first-child {
      border: none;
    }

    thead tr:first-child th:last-child {
      border-top-right-radius: 4px;
    }

    tbody tr:first-child td:first-child {
      border-top-left-radius: 4px;
    }

    tbody tr:last-child td:first-child {
      border-top: none;
    }

    tbody tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }

    tbody tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
}

.removeRow {
  th {
    &:first-child {
      background: transparent;
      border: none;
      border-right: 1px solid #e6e7ed;
    }
  }
}

.footerCellWidth {
  width: 45%;
}

.tableHeading {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.tableBottom {
  background-color: #F0F4F6;
  font-size: 14px;
  font-weight: 500;
  height: 40px!important;
}